main.document {
  font-family: 'Pretendard', sans-serif;
  margin: 0;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  line-height: 24px;
}

main.document ul, main.document ol {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.02em;
}

main.document li {
  padding: 0;
  margin-bottom: 10px;
  list-style-type: none;
}

main.document a {
  text-decoration: underline;
}

main.document a:link, main.document a:visited {
  color: #0085FF;
}

main.document article {
  display: block;
  width: 100%;
}


main.document article.sub-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  overflow: scroll;
  transition: transform .2s ease-out;
  transform: translate(100%, 0);
}

main.document article.sub-content.show {
  transform: translate(0, 0);
}

main.document section {
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 56px;
}

main.document .sub-section {
  margin-bottom:16px;
}

main.document nav {
  height: 64px;
  padding: 0 20px;
  background: #616B70;
  line-height: 64px;
}

main.document nav a {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-size: 18px;
  font-weight: 700;
  color: #ffffff;
}

main.document nav a:before {
  content: '';
  padding-left: 30px;
  background-image: url('https://scan.dagada.co.kr/assets/images/back.svg');
  background-repeat: no-repeat;
}

main.document h1, main.document h2, main.document h3 {
  margin: 0;
}

main.document h1 {
  font-size: 26px;
  font-weight: 700;
  margin: 0 0 16px 0;
  line-height: 34px;
  letter-spacing: -0.04em;
}

main.document h1.small {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
}

main.document h2 {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.04em;
  margin: 0 0 24px 0;
  color: #292929;
}

main.document h3 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  margin-bottom:8px;
}

main.document .none {
  display: none;
}

main.document .poster {
  text-align: center;
  margin-bottom: 34px;
}

main.document .poster .media {
  width: 100%;
}

main.document .event-desc {
  font-size: 18px;
  font-weight: 200;
}

main.document .list {
}

main.document .list .item {
  
}

main.document .content {
  padding: 0 24px 24px 24px;
  margin-bottom: 10px;
}

main.document .tool-box {
}

main.document .tool-box h2 {
  padding-top: 16px;
  border-top: 1px solid #000000;
}

main.document .tool-box > ul {
  display: flex;
  flex-direction: row;
  align-content: space-evenly;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
  border-bottom: 1px solid #000000;
  padding-bottom: 16px;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

main.document .tool-box > ul li {
  padding: 0;
  list-style-type: none;
}

main.document .tool-box > ul li a {
  padding-right: 12px;
  display: block;
  box-sizing: content-box;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: top center;
}

main.document .tool-box > ul li:last-child a {
  /*padding-right: 0;*/
}

main.document .tool-box > ul li a:before {
  content: '';
}

main.document a.homepage {
  background: url('https://scan.dagada.co.kr/assets/images/homepage.svg');
}

main.document a.youtube {
  background: url('https://scan.dagada.co.kr/assets/images/youtube.svg');
}

main.document a.instagram {
  background: url('https://scan.dagada.co.kr/assets/images/instagram.svg');
}

main.document a.facebook {
  background: url('https://scan.dagada.co.kr/assets/images/facebook.svg');
}

main.document a.naver-blog {
  background: url('https://scan.dagada.co.kr/assets/images/naver-blog.svg');
}

main.document a.kakao-story {
  background: url('https://scan.dagada.co.kr/assets/images/kakao-story.svg');
}

main.document a.kakao-channel {
  background: url('https://scan.dagada.co.kr/assets/images/kakao-channel.svg');
}

main.document ul.list li {
  background: #F1F1F0;
  border-radius: 8px;
  margin: 0 0 10px 0;
  line-height: 22px;
}

main.document ul.list li.download {
  background: #FFFFFF;
  border: 1px solid #000000;
  border-radius: 8px;
  margin: 0 0 10px 0;
  line-height: 22px;
}

main.document ul.list li a {
  display: block;
  padding: 16px 16px 16px 42px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  background: url('https://scan.dagada.co.kr/assets/images/link.svg') no-repeat 16px 50%;
}

main.document ul.list li a:visited {
  color: #999999;
  background: url('https://scan.dagada.co.kr/assets/images/link-visited.svg') no-repeat 16px 50%;
}

main.document ul.list li.download a {
  display: block;
  padding: 16px 16px 16px 48px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  background: url('https://scan.dagada.co.kr/assets/images/download.svg') no-repeat 12px 50%;
}

main.document ul.sub-list li {
  line-height: 24px;
  font-weight: 300;
  color: #292929;
}

main.document ul.sub-list li:before {
  content: '· ';
}

main.document .content p {
  margin: 0 0 20px 0;
  line-height: 24px;
  font-weight: 300;
  color: #292929;
}


main.document .intro-dagada-card {
  border-radius: 24px;
  padding: 24px 16px;
  background-color: #F1F5F9;
  display:flex;
  align-items: center;
  text-align: center;
}
main.document .intro-dagada-card .card-logo {
  margin-bottom: 8px;
}
main.document .intro-dagada-card .card-logo-text {
  margin-bottom: 12px;
}
main.document .intro-dagada-card h2 {
  color: #8595AB
}
main.document .intro-dagada-card p {
  color: #8595AB
  
}
main.document .intro-dagada-card :last-child {
  margin-bottom:0;
}

.document-wrap footer {
  margin: 16px auto;
  height: 34px;
  line-height: 34px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}
.document-wrap .footer-logo {
  width: 80px;
  margin: auto;
}

main.document .acc-2022-wrap img {
  width: 100%;
}

footer .intro-dagada-card {
  margin: 0 24px 16px 24px;
  border-radius: 24px;
  padding: 24px 16px;
  background-color: #F1F5F9;
  display:flex;
  align-items: center;
  text-align: center;
}
footer .intro-dagada-card .card-logo {
  margin-bottom: 8px;
}
footer .intro-dagada-card .card-logo-text {
  margin-bottom: 12px;
}
footer .intro-dagada-card h2 {
  color: #8595AB
}
footer .intro-dagada-card p {
  color: #8595AB
  
}
footer .intro-dagada-card :last-child {
  margin-bottom:0;
}

/* ---------------------------------- */

main.document .table{
  border-width: 4px;
  width: 100%;
}
main.document .table tr, li{
  font-size: 0.875rem;
  line-height: 1.25rem;
}
main.document .table .line{
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  border-style: solid;
  border-top-width: 1px;
}
main.document .table th{
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
  padding-left: 1.5rem;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: left;
  padding-right: 0.75rem;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}
main.document .table tr .textCenter{
  text-align: center;
}

main.document .px-3{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
main.document .px-4{
  padding-left: 1rem;
  padding-right: 1rem;
}

main.document .py-2{
  padding-left: 1rem;
  padding-right: 1rem;
}
main.document .py-3\.5{
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
main.document .py-4{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
main.document .pr-3{
  padding-right: 0.75rem;
}
main.document .text-sm{
  font-size: 0.875rem;
  line-height: 1.25rem;
}
main.document .font-medium{
  font-weight: 500;
}
main.document .font-semibold{
  font-weight: 600;
}
main.document .sm\:pl-6{
  padding-left: 1.5rem;
}
main.document .sm\:px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
main.document .whitespace-nowrap{
  white-space: nowrap;
}
main.document .woori svg{
  display: inline;
}